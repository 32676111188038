import { isSubscriber } from '@alltrails/context/utils/isSubscriber';
import useUser from '@alltrails/context/hooks/useUser';
import { useSearchParams } from 'next/navigation';

const useShouldRenderPlusCta = () => {
  const user = useUser();
  const searchParams = useSearchParams();

  const offerTrial = searchParams.get('offer-trial') === 'true';

  const shouldShowPromo = !user || (!isSubscriber(user) && user.isPromoEligible);
  const renderPlusCta = offerTrial && shouldShowPromo;

  return renderPlusCta;
};

export default useShouldRenderPlusCta;
