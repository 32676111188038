import InfoDividerDot from '@alltrails/shared/components/cards/InfoDividerDot';
import { getUrlFromSlug } from '@alltrails/shared/utils/cardLinks';
import LinkInfoRenderer from '@alltrails/denali/components/LinkInfoRenderer';
import ShowMoreText from '@alltrails/shared/components/ShowMoreText';
import { ratingToFormattedRating } from '@alltrails/shared/utils/dataConversions';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import StarFilled from '@alltrails/denali/icons/StarFilled';
import classNames from 'classnames';
import Link from '@alltrails/denali/components/Link';
import { COLOR_NEUTRAL_50 } from '@alltrails/denali/tokens';
import usePublicBaseUrl from '@alltrails/context/hooks/usePublicBaseUrl';
import { useMemo } from 'react';
import { useLocationMap } from '../LocationMap/LocationMapContext';
import styles from './TopResults.module.scss';
import AreaCarousel from './AreaCarousel';
import { SubLocationObject } from '@/types/subLocationListPages/shared';

type Props = {
  area: SubLocationObject;
  className?: string;
  ranking: number;
  showBorder: boolean;
  photoType: string;
  handleMouseEnter: (area: SubLocationObject) => void;
  handleMouseLeave: () => void;
};

const TopAreasCard = ({ area, className, ranking, showBorder, photoType, handleMouseEnter, handleMouseLeave }: Props) => {
  const name = area.name;
  const avgRating = area.avg_rating;
  const languageRegionCode = useLanguageRegionCode();
  const { cardHoveredPark, hoveredCityOrState } = useLocationMap();
  const publicBaseUrl = usePublicBaseUrl();
  const href = getUrlFromSlug(`/${area.url}`, publicBaseUrl, languageRegionCode);
  const localizedRating = ratingToFormattedRating(area.avg_rating, languageRegionCode);
  const areaId = 'ID' in area ? area.ID : area.id;
  const showMoreBackgroundColor = useMemo(() => {
    const isCardHovered = cardHoveredPark?.ID === String(areaId) || hoveredCityOrState?.id === areaId;
    return isCardHovered ? COLOR_NEUTRAL_50 : undefined;
  }, [areaId, cardHoveredPark?.ID, hoveredCityOrState?.id]);

  return (
    <div
      className={classNames(styles.topResultCardContainer, className)}
      title={name}
      onMouseEnter={() => handleMouseEnter(area)}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.resultRanking}>{ranking}</div>
      <div className={styles.resultCardContent}>
        <Link href={href} target="_blank">
          <AreaCarousel area={area} defaultUrl={area?.profile_photo_url} photoType={photoType} />
        </Link>
        <div className={styles.titleAndBookmark}>
          <div className={styles.titleAndStats}>
            <div className={styles.rankNameAndBookmark}>
              <LinkInfoRenderer linkInfo={{ href, target: '_blank' }} className={styles.resultName}>
                <span className={styles.desktopRanking}>#{ranking} - </span>
                {name}
              </LinkInfoRenderer>
            </div>
            {(!!avgRating || !!area?.state_name) && (
              <Link className={styles.resultStatsLink} href={href} target="_blank" title={name}>
                <div className={styles.resultStats}>
                  {!!avgRating && (
                    <span className={styles.star}>
                      <StarFilled color="currentColor" />
                    </span>
                  )}
                  <div className={styles.textContent}>
                    {!!avgRating && (
                      <span className={styles.rating} data-testid="rating">
                        {localizedRating}
                      </span>
                    )}
                    {!!area?.state_name && (
                      <div className={styles.resultStat}>
                        {!!avgRating && <InfoDividerDot className={styles.infoDot} />}
                        <span>{area.state_name}</span>
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            )}
            {!!area.overview && (
              <Link href={href} target="_blank" className={styles.resultStatsLink} title={name}>
                <ShowMoreText
                  text={area.overview}
                  maxLines={3}
                  className={styles.resultDescription}
                  testIdPrefix={`${area.id}-description`}
                  preventExpansion
                  useShortText
                  showMoreBackgroundColor={showMoreBackgroundColor}
                />
              </Link>
            )}
          </div>
        </div>
      </div>
      {showBorder && <div className={styles.border} />}
    </div>
  );
};

export default TopAreasCard;
