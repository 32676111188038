import classNames from 'classnames';
import { MouseEvent } from 'react';
import ArrowSq from '../../icons/ArrowSq';
import IconButton from '../IconButton';
import styles from './styles/styles.module.scss';

type ArrowButtonProps = { className?: string; onClick: () => void; uniqueId: string; title: string };

const ArrowButton = (orientation: 'left' | 'right') =>
  function ({ className, onClick, uniqueId, title }: ArrowButtonProps) {
    return (
      <IconButton
        className={classNames(styles[`${orientation}Arrow`], className)}
        icon={{ Component: ArrowSq, orientation }}
        onClick={(e?: MouseEvent) => {
          e?.preventDefault();
          e?.stopPropagation();
          onClick();
        }}
        tabIndex={-1}
        title={title}
        testId={`${uniqueId}_ArrowButton_${orientation}`}
        size="md"
        variant="elevated"
      />
    );
  };

export const LeftArrow = ArrowButton('left');
export const RightArrow = ArrowButton('right');
