'use client';

import { useExperiments } from '@alltrails/experiments';
import getLocationName from '@alltrails/shared/utils/getLocationName';
import Link from '@alltrails/denali/components/Link';
import styles from '../TitleAndStats.module.scss';

type PoiLocationLinkProps = {
  locationDetails: {
    countryName: string;
    stateName?: string | null;
    cityName?: string | null;
    associatedLocationSlug?: string;
    primaryPark?: {
      name: string;
      slug: string;
    };
  };
};

const PoiLocationLink = ({ locationDetails }: PoiLocationLinkProps) => {
  const experiments = useExperiments();

  const poiPageExperiment = experiments['web-discover-rm-poi-explore-map-12-24']?.value;
  if (poiPageExperiment !== 'treatment') {
    return null;
  }
  const { primaryPark, countryName, stateName, cityName, associatedLocationSlug } = locationDetails;

  return (
    <div>
      <span className={styles.dividerDot}>·</span>
      {primaryPark ? (
        <Link href={`/parks/${primaryPark.slug}`} size="md">
          {primaryPark.name}
        </Link>
      ) : (
        <Link href={`/${associatedLocationSlug}`} size="md">
          {getLocationName({ country_name: countryName, region: stateName, city: cityName })}
        </Link>
      )}
    </div>
  );
};

export default PoiLocationLink;
