import { baseApi } from '@alltrails/redux-helpers';
import {
  AreaSearchResult,
  AreaType,
  PlaceSearchResult,
  POIHit,
  TrailHit,
  TrailSearchResult,
  PhotospotHit,
  type POISearchResult
} from '@alltrails/search/types/algoliaResultTypes';
import ExploreServicePayload from '../types/ExploreServicePayload';
import ExploreServiceResponse, { type ExploreServiceSearchResult } from '../types/ExploreServiceResponse';

const url = '/api/alltrails/explore/v1/search';

const poiAttributesToRetrieve: (keyof POIHit)[] = [
  'ID',
  'location_label',
  'location_type',
  'name',
  'photos_count',
  'slug',
  'subtype',
  'popularity',
  'state_name',
  'trails_count',
  'type_label',
  '_geoloc'
];

const photospotAttributesToRetrieve: (keyof PhotospotHit)[] = [
  'ID',
  'objectID',
  'location_label',
  'location_type',
  'name',
  'popularity',
  'slug',
  'type_label',
  'subtype',
  '_geoloc',
  'photos_count',
  'trails_count',
  'state_name'
];

const trailAttributesToRetrieve: (keyof TrailHit)[] = [
  'ID',
  'area_name',
  'avg_rating',
  'area_slug',
  'city_url',
  'difficulty_rating',
  'description',
  'duration_minutes',
  'duration_minutes_cycling',
  'duration_minutes_hiking',
  'duration_minutes_mountain_biking',
  'duration_minutes_trail_running',
  'is_closed',
  'length',
  'location_label',
  'name',
  'num_reviews',
  'slug',
  '_geoloc',
  '_cluster_geoloc',
  'estimated_time_to_complete'
];

export const exploreServiceApi = baseApi.enhanceEndpoints({ addTagTypes: ['Places', 'POI-Trails', 'Trails'] }).injectEndpoints({
  endpoints: builder => ({
    getAreaTrails: builder.mutation<
      Pick<ExploreServiceResponse<TrailSearchResult>, 'boundingBox' | 'searchResults'>,
      Pick<ExploreServicePayload, 'location'>
    >({
      query: payload => {
        const data: ExploreServicePayload = { ...payload, resultsToInclude: ['boundingBox', 'searchResults'] };
        return { url, data, method: 'post' };
      }
    }),
    getLocationData: builder.mutation<
      Pick<ExploreServiceResponse<TrailSearchResult>, 'boundingBox' | 'searchResults'>,
      Pick<ExploreServicePayload, 'filters' | 'limit' | 'location' | 'page' | 'recordAttributesToRetrieve' | 'sort'>
    >({
      query: payload => {
        const data: ExploreServicePayload = { ...payload, resultsToInclude: ['boundingBox', 'searchResults'] };
        return { url, data, method: 'post' };
      }
    }),
    getParks: builder.mutation<
      Pick<ExploreServiceResponse<AreaSearchResult>, 'searchResults'>,
      Pick<ExploreServicePayload, 'limit' | 'location'> & { areaTypes?: AreaType[] }
    >({
      query: ({ areaTypes, limit, location }) => {
        const data: ExploreServicePayload = {
          filters: {
            collections: ['parks'],
            ...(areaTypes && { areaType: areaTypes })
          },
          ...(limit && { limit }),
          location,
          resultsToInclude: ['searchResults']
        };
        return { url, data, method: 'post' };
      }
    }),
    getPlaces: builder.query<Pick<ExploreServiceResponse<PlaceSearchResult>, 'searchResults'>, Pick<ExploreServicePayload, 'location' | 'limit'>>({
      query: payload => {
        const data: ExploreServicePayload = {
          ...payload,
          recordTypesToReturn: ['area', 'city', 'country', 'state'],
          resultsToInclude: ['searchResults'],
          sort: 'most_popular'
        };
        return { url, data, method: 'post' };
      },
      keepUnusedDataFor: 3, // seconds
      providesTags: ['Places']
    }),
    getPOITrails: builder.query<
      Pick<ExploreServiceResponse<TrailSearchResult | POISearchResult>, 'place' | 'searchResults' | 'summary'>,
      Pick<ExploreServicePayload, 'filters' | 'location' | 'sort'>
    >({
      query: payload => {
        const attributes: string[] = [...poiAttributesToRetrieve, ...trailAttributesToRetrieve, 'polyline'];
        const data: ExploreServicePayload = {
          ...payload,
          recordAttributesToRetrieve: Array.from(new Set(attributes)),
          recordTypesToReturn: ['poi', 'trail'],
          resultsToInclude: ['place', 'searchResults', 'summary']
        };
        return { url, data, method: 'post' };
      },
      keepUnusedDataFor: 3, // seconds
      providesTags: ['POI-Trails']
    }),
    getResults: builder.query<
      Pick<ExploreServiceResponse<ExploreServiceSearchResult>, 'searchResults' | 'summary'>,
      Pick<ExploreServicePayload, 'filters' | 'location' | 'recordTypesToReturn' | 'sort'>
    >({
      query: payload => {
        const attributes: string[] = [];
        if (payload.recordTypesToReturn?.includes('poi')) {
          attributes.push(...poiAttributesToRetrieve);
        }
        if (payload.recordTypesToReturn?.includes('trail')) {
          attributes.push(...trailAttributesToRetrieve);
        }
        if (payload.recordTypesToReturn?.includes('photospot')) {
          attributes.push(...photospotAttributesToRetrieve);
        }
        const data: ExploreServicePayload = {
          ...payload,
          recordAttributesToRetrieve: Array.from(new Set(attributes)),
          resultsToInclude: ['searchResults', 'summary']
        };
        return { url, data, method: 'post' };
      },
      keepUnusedDataFor: 3, // seconds
      providesTags: ['Trails']
    }),
    getResultsBoundingBox: builder.mutation<
      Pick<ExploreServiceResponse<TrailSearchResult>, 'boundingBox' | 'searchResults' | 'summary'>,
      Pick<ExploreServicePayload, 'expandSearch' | 'filters' | 'location' | 'sort'>
    >({
      query: payload => {
        const data: ExploreServicePayload = {
          ...payload,
          resultsToInclude: ['searchResults', 'boundingBox', 'summary']
        };
        return { url, data, method: 'post' };
      },
      invalidatesTags: ['Trails'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { expandSearch, ...rest } = args;
        const { data } = await queryFulfilled;
        rest.location = { ...data.boundingBox, mapRotation: 0 };
        dispatch(exploreServiceApi.util.upsertQueryData('getResults', rest, data));
      }
    })
  })
});

export const {
  useGetAreaTrailsMutation,
  useGetLocationDataMutation,
  useGetParksMutation,
  useGetPOITrailsQuery,
  useGetResultsQuery,
  useGetResultsBoundingBoxMutation,
  useLazyGetPlacesQuery
} = exploreServiceApi;
