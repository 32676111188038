'use client';

import logShowMoreClicked from '@alltrails/analytics/events/logShowMoreClicked';
import PageType from '@alltrails/analytics/enums/PageType';
import ShowMoreLocation from '@alltrails/analytics/enums/ShowMoreLocation';
import ShowMoreText from '@alltrails/shared/components/ShowMoreText';
import { BREAKPOINT_MD_SIZE } from '@alltrails/denali/tokens';
import { useMediaQuery } from '@alltrails/core';
import PlusCta from '../PlusCta';
import styles from './Description.module.scss';
import useShouldRenderPlusCta from '@/hooks/useShouldRenderPlusCta';

type Props = {
  descriptionText: string;
  isBot: boolean;
  pageType?: PageType;
  descriptionHtml?: string;
};

const Description = ({ descriptionText, isBot, pageType, descriptionHtml }: Props) => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MD_SIZE}px)`);
  const defaultMaxLines = isMobile ? 2 : 3;

  const renderPlusCta = useShouldRenderPlusCta();

  const botDescription = descriptionHtml ? (
    <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} className={styles.locationDescription} />
  ) : (
    <div className={styles.locationDescription}>{descriptionText}</div>
  );

  return (
    <>
      {isBot ? (
        botDescription
      ) : (
        <ShowMoreText
          text={descriptionHtml || descriptionText}
          innerHtml={descriptionHtml}
          className={styles.locationDescription}
          maxLines={defaultMaxLines}
          testIdPrefix="location-description"
          useShortText
          skipHtmlSanitization
          onClick={() => {
            if (pageType) logShowMoreClicked({ page_type: pageType, show_more_location: ShowMoreLocation.Overview });
          }}
        />
      )}
      {renderPlusCta && <PlusCta />}
      <div className={styles.divider} />
    </>
  );
};

export default Description;
