'use client';

import { FormattedMessage, FormattedNumber } from 'react-intl';

type Props = {
  count: number;
};

const ReviewCount = ({ count }: Props) => {
  return <FormattedMessage defaultMessage="{reviewNum} reviews" values={{ reviewNum: <FormattedNumber value={count} /> }} />;
};

export default ReviewCount;
