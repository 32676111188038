'use client';

import { FormattedMessage } from 'react-intl';
import Link from '@alltrails/denali/components/Link';
import classNames from 'classnames';
import styles from '../TitleAndStats.module.scss';
import { ParkInfoType } from '@/types/locationDetailsPages/shared';
import { ParkRanking as ParkRankingType, ParkRankInfo } from '@/types/shared';

const getLink = (item: ParkRankInfo, isCountry?: boolean) => {
  const casedTitle = isCountry ? item.title : item.title.toLowerCase();

  return (
    <>
      <Link className={classNames(styles.link, isCountry && styles.country)} href={item.slug} title={item.title}>
        {casedTitle}
      </Link>
      {isCountry && <div className={styles.countrySpace}>:&nbsp;</div>}
    </>
  );
};

type Props = {
  parkInfo?: ParkInfoType;
};

const ParkRanking = ({ parkInfo }: Props) => {
  const ranking = parkInfo?.ranking as ParkRankingType;

  if (!ranking) {
    return null;
  }

  return (
    <>
      {getLink(ranking.inLocation, true)}
      <FormattedMessage defaultMessage="#{position} of {total}" values={{ position: ranking.position, total: ranking.total }} />{' '}
      {getLink(ranking.ofParkType)}
    </>
  );
};

export default ParkRanking;
