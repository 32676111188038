import { AlgoliaTrail, trailFromAlgoliaTrail } from '@alltrails/trails';
import { useFeatures } from '@alltrails/features';
import InfoDividerDot from '@alltrails/shared/components/cards/InfoDividerDot';
import { getUrlFromSlug } from '@alltrails/shared/utils/cardLinks';
import logTrailCardClicked from '@alltrails/analytics/events/logTrailCardClicked';
import logSaveClicked from '@alltrails/analytics/events/logSaveClicked';
import TrailCardLocation from '@alltrails/analytics/enums/TrailCardLocation';
import LinkInfoRenderer from '@alltrails/denali/components/LinkInfoRenderer';
import BookmarkButton from '@alltrails/shared/components/BookmarkButton';
import ShowMoreText from '@alltrails/shared/components/ShowMoreText';
import { metersToFormattedDistance, minutesToFormattedTime, ratingToFormattedRating } from '@alltrails/shared/utils/dataConversions';
import useDifficultyText from '@alltrails/shared/hooks/useDifficultyText';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import StarFilled from '@alltrails/denali/icons/StarFilled';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { PrivacyPolicyOptions } from '@alltrails/shared/types/privacyPolicy';
import { useCallback } from 'react';
import Link from '@alltrails/denali/components/Link';
import { COLOR_NEUTRAL_50 } from '@alltrails/denali/tokens';
import CardLocation from '@alltrails/analytics/enums/CardLocation';
import MapLocation from '@alltrails/analytics/enums/MapLocation';
import MapType from '@alltrails/analytics/enums/MapType';
import TapFeature from '@alltrails/analytics/enums/TapFeature';
import usePublicBaseUrl from '@alltrails/context/hooks/usePublicBaseUrl';
import { useLocationMap } from '../LocationMap/LocationMapContext';
import styles from './TopResults.module.scss';
import TrailCarousel from './TrailCarousel';
import { useSelector } from '@/utils/redux';

type TrailWithProfilePhotoUrl = AlgoliaTrail & {
  profile_photo_url?: string;
};

type Props = {
  trail: TrailWithProfilePhotoUrl;
  className?: string;
  ranking: number;
  showBorder: boolean;
  isFavorite: boolean;
  onFavoriteClick: (itemType: 'trail' | 'map' | 'track', itemId: number, itemPrivacy?: PrivacyPolicyOptions | undefined) => void;
  isShowMoreClicked?: boolean;
};

const TopTrailsCard = ({ trail, className, ranking, showBorder, isFavorite, onFavoriteClick, isShowMoreClicked }: Props) => {
  const trailToRender = trailFromAlgoliaTrail(trail);
  const {
    name,
    avgRating,
    defaultActivityStats: { difficulty } = {},
    trailCounts: { reviewCount },
    trailGeoStats: { length, durationMinutes },
    trailDetail
  } = trailToRender;
  const displayMetric = useSelector(state => state.context.displayMetric);
  const languageRegionCode = useLanguageRegionCode();
  const { cardHoveredResult, setCardHoveredResult } = useLocationMap();
  const intl = useIntl();
  const publicBaseUrl = usePublicBaseUrl();
  const href = getUrlFromSlug(`/${trail.slug}`, publicBaseUrl, languageRegionCode);
  const localizedRating = ratingToFormattedRating(avgRating, languageRegionCode);
  const difficultyText = useDifficultyText(difficulty);
  const minutes = parseInt(durationMinutes ?? '0', 10);
  const isRoundEstTimeToCompletionEnabled = useFeatures()?.includes('round_est_time_completion');

  const handleTrailClick = useCallback(
    (tapFeature: TapFeature) => {
      logTrailCardClicked({
        trail_id: String(trail.ID),
        card_location: TrailCardLocation.Seo,
        detailed_card_location: CardLocation.HighlightsCarousel,
        tap_feature: tapFeature
      });
    },
    [trail.ID]
  );

  const handleFavoriteClick = () => {
    logSaveClicked({
      detailed_card_location: CardLocation.LocationPage,
      save_location: MapLocation.Explore,
      map_id: trail.ID,
      map_type: MapType.Trail
    });
    onFavoriteClick('trail', trail.ID);
  };

  const handleSetHoveredResult = useCallback(() => {
    if (trail._geoloc !== undefined) {
      setCardHoveredResult({
        _geoloc: trail._geoloc,
        _cluster_geoloc: trail._cluster_geoloc,
        ID: String(trail.ID),
        type: trail.type
      });
    }
  }, [setCardHoveredResult, trail.ID, trail._cluster_geoloc, trail._geoloc, trail.type]);

  const handleClearHoveredResult = useCallback(() => setCardHoveredResult(undefined), [setCardHoveredResult]);

  return (
    <div
      className={classNames(styles.topResultCardContainer, className)}
      title={name}
      onMouseEnter={handleSetHoveredResult}
      onMouseLeave={handleClearHoveredResult}
    >
      <div className={styles.resultRanking}>{ranking}</div>
      <div className={styles.resultCardContent}>
        <Link href={href} target="_blank" onClick={() => handleTrailClick(TapFeature.TrailImage)} outsideOfMugen>
          <TrailCarousel trail={trailToRender} defaultUrl={!isShowMoreClicked ? trail.profile_photo_url : undefined} />
        </Link>
        <div className={styles.titleAndBookmark}>
          <div className={styles.titleAndStats}>
            <div className={styles.rankNameAndBookmark}>
              <LinkInfoRenderer
                linkInfo={{ href, outsideOfMugen: true, target: '_blank' }}
                className={styles.resultName}
                onClick={() => handleTrailClick(TapFeature.TrailName)}
              >
                <span className={styles.desktopRanking}>#{ranking} - </span>
                {name}
              </LinkInfoRenderer>
              <BookmarkButton className={styles.desktopBookmark} size="sm" isSelected={isFavorite} onClick={handleFavoriteClick} onWhiteBackground />
            </div>
            <Link className={styles.resultStatsLink} href={href} outsideOfMugen target="_blank" title={name}>
              <div className={styles.resultStats}>
                {!!avgRating && (
                  <span className={styles.star}>
                    <StarFilled color="currentColor" />
                  </span>
                )}
                <div className={styles.textContent}>
                  {!!avgRating && (
                    <span className={styles.rating} data-testid="rating">
                      {localizedRating}
                    </span>
                  )}
                  {!!reviewCount && (
                    <span className={styles.reviews} data-testid="review-count">
                      ({reviewCount})
                    </span>
                  )}
                  {!!difficulty && (
                    <div data-testid="difficulty" className={styles.resultStat}>
                      {(!!avgRating || !!reviewCount) && <InfoDividerDot className={styles.infoDot} />}
                      <span>{difficultyText}</span>
                    </div>
                  )}
                  {!!length && (
                    <div data-testid="distance" className={styles.resultStat}>
                      {(!!difficulty || !!reviewCount) && <InfoDividerDot className={styles.infoDot} />}
                      <span>{metersToFormattedDistance(intl, languageRegionCode, length, displayMetric ? 'km' : 'mi', 1)}</span>
                    </div>
                  )}
                  {!!minutes && (
                    <div className={styles.estimatedTime} data-testid="duration">
                      {isRoundEstTimeToCompletionEnabled && trail?.estimated_time_to_complete ? (
                        <>
                          <InfoDividerDot className={styles.infoDot} />
                          {`${intl.formatMessage({ defaultMessage: ' Est.' })} ${trail.estimated_time_to_complete.value} ${trail.estimated_time_to_complete.unit}`}
                        </>
                      ) : (
                        <>
                          <InfoDividerDot className={styles.infoDot} />
                          {minutesToFormattedTime(intl, minutes, true)}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Link>
            {!!trailDetail?.description && (
              <Link
                href={href}
                target="_blank"
                className={styles.resultStatsLink}
                onClick={() => handleTrailClick(TapFeature.MoreLink)}
                outsideOfMugen
                title={name}
              >
                <ShowMoreText
                  text={trailDetail?.description}
                  maxLines={3}
                  className={styles.resultDescription}
                  testIdPrefix={`${trail.id}-description`}
                  preventExpansion
                  useShortText
                  showMoreBackgroundColor={cardHoveredResult?.ID === String(trail.ID) ? COLOR_NEUTRAL_50 : undefined}
                />
              </Link>
            )}
          </div>
          <BookmarkButton className={styles.mobileBookmark} size="sm" isSelected={isFavorite} onClick={handleFavoriteClick} onWhiteBackground />
        </div>
      </div>
      {showBorder && <div className={styles.border} />}
    </div>
  );
};

export default TopTrailsCard;
