import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import useCurrencyCode from '@alltrails/context/hooks/useCurrencyCode';
import useUser from '@alltrails/context/hooks/useUser';
import { Typography } from '@alltrails/core';
import { currencyFormatter } from '@alltrails/data-formatting';
import Button from '@alltrails/denali/components/Button';
import type Plan from '@alltrails/core/types/Plan';
import { useSelector } from '../../utils/redux';
import styles from './styles/styles.module.scss';

// English only - no translations exist for this component
export default function PlusCta() {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const currencyCode = useCurrencyCode();
  const plan = useSelector<Plan>(state => state.plan);

  const formatter = currencyFormatter(currencyCode, languageRegionCode);

  if (languageRegionCode !== 'en-US' || !plan) {
    return null;
  }

  const annualCost = Number(plan.priceTotal);
  const monthlyCost = Number(plan.priceMonth);
  return (
    <div className={styles.buttonContainer}>
      <Button
        text="Start your free AllTrails+ trial"
        linkInfo={{
          href: user ? '/plus/checkout' : '/signup?returnTo=%2Fplus%2Fcheckout'
        }}
        testId="location-try-plus"
        variant="primary"
        width="fullOnMobile"
      />
      <Typography variant="text100">{`Then only ${formatter.format(annualCost)} a year (that's ${formatter.format(
        monthlyCost
      )} a month)`}</Typography>
    </div>
  );
}
